import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hamburger from '../components/Hamburger';
import theme from '../theme';

const MainNav = styled.nav`
  display: flex;

  & > * {
    color: inherit;
    font-weight: 700;
    text-decoration: none;
  }

  @media screen and (max-width: calc(${({ breakPoint }) => breakPoint} - 1px)) {
    background: ${theme.colors.accent};
    color: ${theme.colors.primary};
    flex-direction: column;
    font-size: 2rem;
    height: 100vh;
    padding-top: 4.25rem;
    position: fixed;
    top: 0;
    right: 0;
    text-align: left;
    text-transform: uppercase;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: 10;

    & > a {
      padding: .5rem 3rem;
      text-align: center;
      width: 100%;
      &.active,
      &:hover {
        background: ${theme.colors.primary};
        color: ${theme.colors.accent};
      }
    }
  }

  @media screen and (min-width: ${({ breakPoint }) => breakPoint}) {
    align-items: center;
    flex-direction: row;
    height: auto;
  
    & > * {
      margin-left: 1.25rem;
    }
    & > a {
      &.active,
      &:hover {
        opacity: .5;
      }
    }
  }
`;

const ResponsiveNav = ({ breakPoint, breakPointSm, children }) => {
  const [open, setOpen] = useState(false);

  const hamburgerNode = useRef();
  const navNode = useRef();
  useEffect(() => {
    const listener = (event) => {
      if (
        !navNode.current
        || navNode.current.contains(event.target)
        || hamburgerNode.current.contains(event.target)
      ) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  },
  [hamburgerNode, navNode, setOpen],
  );

  return (
    <>
      <Hamburger
        hamburgerRef={hamburgerNode}
        breakPoint={breakPoint}
        breakPointSm={breakPointSm}
        open={open}
        setOpen={setOpen}
      />
      <MainNav
        ref={navNode}
        breakPoint={breakPoint}
        open={open}
      >
        {children({ setOpen })}
      </MainNav>
    </>
  );
};

ResponsiveNav.defaultProps = {
  breakPointSm: '',
};

ResponsiveNav.propTypes = {
  breakPoint: PropTypes.string.isRequired, // as min-width
  breakPointSm: PropTypes.string,
};

export default ResponsiveNav;

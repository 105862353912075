import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../theme';

const ResponsiveSection = ({
  background,
  children,
  className,
  color,
  style,
}) => (
  <section className={className} style={{ background, color, ...style }}>
    <div className="inner-div">{children}</div>
  </section>
);

ResponsiveSection.defaultProps = {
  background: '',
};

ResponsiveSection.propTypes = {
  background: PropTypes.string,
};

const StyledResponsiveSection = styled(ResponsiveSection)`
  .inner-div {
    margin: 0 auto;
    max-width: ${theme.layout.maxWidth};
    padding: calc(${theme.fonts.rhythm} * 3) ${theme.layout.wrapperPaddingSm};
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    .inner-div {
      padding: calc(${theme.fonts.rhythm} * 3) ${theme.layout.wrapperPadding};
    }
  }
`;

export default StyledResponsiveSection;

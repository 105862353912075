import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import theme from '../../theme';
import Header from '../Header';
import Footer from '../Footer';
import './index.scss';
import GlobalStyle from '../../globalStyle';

const PaddedMain = styled.main`
  padding-top: calc(${theme.fonts.rhythm} * 2);

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    padding-top: calc(${theme.fonts.rhythm} * 3);
  }
`;

const Main = styled.main``;

const Layout = ({ children, hideSignInLink = false, pagePadding = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const MainSection = pagePadding
    ? PaddedMain
    : Main;

  return (
    <div className="layoutContainer">
      <GlobalStyle />
      <div className="content">
        <Header siteTitle={data.site.siteMetadata.title} />
        <MainSection>
          {children}
        </MainSection>
      </div>
      <Footer hideSignInLink={hideSignInLink} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideSignInLink: PropTypes.bool,
  pagePadding: PropTypes.bool,
};

export default Layout;

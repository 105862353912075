import { Link, navigate } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'digital-rabbit-cl';
import styled from 'styled-components';

import ResponsiveNav from './ResponsiveNav';
import theme from '../theme';

const StyledButton = styled(Button)`
  @media screen and (max-width: calc(${theme.breakpoints.md} - 1px)) {
    align-self: center;
    margin: .75rem 2rem;

    &:hover {
      color: ${theme.colors.accent};
    }
  }
`;

const NavLink = ({ children, to, ...props }) => <Link to={to} activeClassName="active" {...props}>{children}</Link>;

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
};

const Header = ({ className }) => (
  <header
    style={{
      position: 'absolute',
      width: '100%',
    }}
  >
    <div className={className}>
      <Link
        to="/"
        style={{ textDecoration: `none` }}
      >
        <div
          style={{
            backgroundImage: 'url(/logo-light.svg)',
            backgroundRepeat: 'no-repeat',
            height: '3em',
            width: '10em',
          }}
        />
      </Link>
      <ResponsiveNav breakPoint={theme.breakpoints.md} breakPointSm={theme.breakpoints.sm}>
        {({ setOpen }) => (
          <>
            {/*
              <NavLink
                onClick={() => setOpen(false)}
                to="/portfolio"
              >
                Portfolio
              </NavLink>
            */}
            <StyledButton
              size="small"
              color={theme.fonts.color}
              hoverColor={theme.colors.primary}
              hoverTextColor={theme.colors.secondary}
              onClick={() => {
                setOpen(false);
                navigate('/contact');
              }}
              outline
            >
              Contact
            </StyledButton>
          </>
        )}
      </ResponsiveNav>
    </div>
  </header>
);

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  font-size: .75rem;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${theme.layout.maxWidth};
  padding: ${theme.layout.wrapperPaddingSm};

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.fonts.size};
    padding: ${theme.layout.wrapperPadding};
  }
`;

export default StyledHeader;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

const StyledHamburger = styled.button`
  position: ${({ open }) => open ? 'fixed' : 'absolute'};
  right: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2em;
  height: 2em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2em;
    height: 0.25em;
    background: ${theme.colors.primary};
    border-radius: ${theme.borderRadius};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media screen and (min-width: ${({ breakPointSm }) => breakPointSm}) {
    right: 1rem;
  }

  @media screen and (min-width: ${({ breakPoint }) => breakPoint}) {
    display: none;
  }
`;

const Hamburger = ({
  hamburgerRef,
  breakPoint,
  breakPointSm,
  open,
  setOpen,
}) => (
  <StyledHamburger
    ref={hamburgerRef}
    breakPoint={breakPoint}
    breakPointSm={breakPointSm || breakPoint}
    open={open}
    onClick={() => setOpen(!open)}
  >
    <div />
    <div />
    <div />
  </StyledHamburger>
);

Hamburger.propTypes = {
  breakPointSm: '',
};

Hamburger.propTypes = {
  breakPoint: PropTypes.string.isRequired,
  breakPointSm: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Hamburger;
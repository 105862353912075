import { createGlobalStyle } from 'styled-components';

import theme from './theme';

const {
  rhythm,
  lineHeight,
  fontFamily,
  fontWeightHeader,
  fontSizeH1,
  fontSizeH2,
  fontSizeH3,
  fontSizeH4,
  fontSizeH5,
  fontSizeH6,
} = theme.fonts;
const { accent } = theme.colors;
 
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
  }
  @font-face {
    font-family: "San Francisco";
    font-weight: 700;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
  }

  html {
    font-size: ${theme.fonts.size};
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    margin: 0;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block
  }
  
  audio,
  canvas,
  progress,
  video {
    display: inline-block
  }
  
  audio:not([controls]) {
    display: none;
    height: 0
  }
  
  progress {
    vertical-align: baseline
  }
  
  [hidden],
  template {
    display: none
  }
  
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
  }
  
  a:active,
  a:hover {
    outline-width: 0
  }

  a.styled-link {
    background-color: transparent;
    border-bottom: 3px solid ${accent};
    -webkit-box-shadow: inset 0 -4px 0 ${accent};
    box-shadow: inset 0 -4px 0 ${accent};
    color: inherit;
    overflow-wrap: break-word;
    text-decoration: none;
    -webkit-transition: background 0.35s cubic-bezier(0.33, 0.66, 0.66, 1);
    -o-transition: background 0.35s cubic-bezier(0.33, 0.66, 0.66, 1);
    transition: background 0.35s cubic-bezier(0.33, 0.66, 0.66, 1);
    word-break: break-word;
    word-wrap: break-word
  }
  
  a.styled-link:active,
  a.styled-link:hover {
    background-color: ${accent};
  }
  
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
  }
  
  b,
  strong {
    font-weight: inherit;
    font-weight: bolder
  }
  
  dfn {
    font-style: italic
  }
  
  h1 {
    font-size: 2em;
    margin: .67em 0
  }
  
  mark {
    background-color: #ff0;
    color: #000
  }
  
  small {
    font-size: 80%
  }
  
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
  }
  
  sub {
    bottom: -.25em
  }
  
  sup {
    top: -.5em
  }
  
  img {
    border-style: none
  }
  
  svg:not(:root) {
    overflow: hidden
  }
  
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em
  }
  
  figure {
    margin: 1em 40px
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0
  }
  
  optgroup {
    font-weight: ${fontWeightHeader}
  }
  
  button,
  input {
    overflow: visible
  }
  
  button,
  select {
    text-transform: none
  }
  
  [type=reset],
  [type=submit],
  button,
  html [type=button] {
    -webkit-appearance: button
  }
  
  [type=button]::-moz-focus-inner,
  [type=reset]::-moz-focus-inner,
  [type=submit]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0
  }
  
  [type=button]:-moz-focusring,
  [type=reset]:-moz-focusring,
  [type=submit]:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText
  }
  
  fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
  }
  
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
  }
  
  textarea {
    overflow: auto
  }
  
  [type=checkbox],
  [type=radio] {
    box-sizing: border-box;
    padding: 0
  }
  
  [type=number]::-webkit-inner-spin-button,
  [type=number]::-webkit-outer-spin-button {
    height: auto
  }
  
  [type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
  }
  
  [type=search]::-webkit-search-cancel-button,
  [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
  }
  
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: .54
  }
  
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
  }
  
  html {
    font: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'san-serif';
    box-sizing: border-box;
    overflow-y: scroll;
  }
  
  * {
    box-sizing: inherit;
  }
  
  *:before {
    box-sizing: inherit;
  }
  
  *:after {
    box-sizing: inherit;
  }

  body {
    background: ${theme.colors.secondary};
    color: ${theme.colors.primary};
    font-size: ${theme.fonts.size};
    font-family: ${fontFamily};
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }

  img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 0;
  }
  
  h1 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    color: inherit;
    font-family: ${fontFamily};
    font-weight: ${fontWeightHeader};
    text-rendering: optimizeLegibility;
    font-size: ${fontSizeH1};
    line-height: ${lineHeight};
  }
  
  h2 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    color: inherit;
    font-family: ${fontFamily};
    font-weight: ${fontWeightHeader};
    text-rendering: optimizeLegibility;
    font-size: ${fontSizeH2};
    line-height: ${lineHeight};
  }
  
  h3 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(${rhythm} / 2);
    color: inherit;
    font-family: ${fontFamily};
    font-weight: ${fontWeightHeader};
    text-rendering: optimizeLegibility;
    font-size: ${fontSizeH3};
    line-height: ${lineHeight};
  }
  
  h4 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    color: inherit;
    font-family: ${fontFamily};
    font-weight: ${fontWeightHeader};
    text-rendering: optimizeLegibility;
    font-size: ${fontSizeH4};
    line-height: ${lineHeight};
  }
  
  h5 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    color: inherit;
    font-family: ${fontFamily};
    font-weight: ${fontWeightHeader};
    text-rendering: optimizeLegibility;
    font-size: ${fontSizeH5};
    line-height: ${lineHeight};
  }
  
  h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    color: inherit;
    font-family: ${fontFamily};
    font-weight: ${fontWeightHeader};
    text-rendering: optimizeLegibility;
    font-size: ${fontSizeH6};
    line-height: ${lineHeight};
  }
  
  hgroup {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  ul {
    margin-left: ${rhythm};
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    list-style-position: outside;
    list-style-image: none;
  }
  
  ol {
    margin-left: ${rhythm};
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    list-style-position: outside;
    list-style-image: none;
  }
  
  dl {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  dd {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  figure {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  pre {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    font-size: 0.85rem;
    line-height: ${rhythm};
  }
  
  table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
    font-size: 1rem;
    line-height: ${rhythm};
    border-collapse: collapse;
    width: 100%;
  }
  
  fieldset {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  blockquote {
    margin-left: ${rhythm};
    margin-right: ${rhythm};
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  form {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  noscript {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  iframe {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(${rhythm} - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
  }
  
  address {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: ${rhythm};
  }
  
  b {
    font-weight: bold;
  }
  
  strong {
    font-weight: bold;
  }
  
  dt {
    font-weight: bold;
  }
  
  th {
    font-weight: bold;
  }
  
  li {
    margin-bottom: calc(${rhythm} / 2);
  }
  
  ol li {
    padding-left: 0;
  }
  
  ul li {
    padding-left: 0;
  }
  
  li>ol {
    margin-left: ${rhythm};
    margin-bottom: calc(${rhythm} / 2);
    margin-top: calc(${rhythm} / 2);
  }
  
  li>ul {
    margin-left: ${rhythm};
    margin-bottom: calc(${rhythm} / 2);
    margin-top: calc(${rhythm} / 2);
  }
  
  blockquote *:last-child {
    margin-bottom: 0;
  }
  
  li *:last-child {
    margin-bottom: 0;
  }
  
  p *:last-child {
    margin-bottom: 0;
  }
  
  li>p {
    margin-bottom: calc(${rhythm} / 2);
  }
  
  code {
    font-size: 0.85rem;
    line-height: ${rhythm};
  }
  
  kbd {
    font-size: 0.85rem;
    line-height: ${rhythm};
  }
  
  samp {
    font-size: 0.85rem;
    line-height: ${rhythm};
  }
  
  abbr {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }
  
  acronym {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }
  
  abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
  }
  
  thead {
    text-align: left;
  }
  
  td,
  th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding-left: 0.96667rem;
    padding-right: 0.96667rem;
    padding-top: 0.725rem;
    padding-bottom: calc(0.725rem - 1px);
  }
  
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  
  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    html, body {
      font-size: calc(${theme.fonts.size} * .9);
    }
  }

  @media screen and (max-width: ${theme.breakpoints.xs}) {
    html, body {
      font-size: 5.5vw;
    }
  }
`;
 
export default GlobalStyle;
const rhythm = `1.45rem`;

export default {
  borderRadius: '0px',
  breakpoints: {
    xs: `350px`,
    sm: `475px`,
    md: `769px`,
    lg: `1025px`,
  },
  colors: {
    primary: `white`,
    secondary: `black`,
    accent: `#2700ff`,
    error: `#F00`,
    success: `#008000`,
    warning: `#FFEB3B`
  },
  fonts: {
    color: `white`,
    lineHeight: 1.1,
    fontFamily: `'San Francisco', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'san-serif'`,
    fontWeightHeader: 700,
    fontSizeH1: `2.5rem`,
    fontSizeH2: `1.75rem`,
    fontSizeH3: `1.38316rem`,
    fontSizeH4: `1rem`,
    fontSizeH5: `0.85028rem`,
    fontSizeH6: `0.78405rem`,
    rhythm,
    size: `22px`,
  },
  layout: {
    maxWidth: `1280px`,
    wrapperPaddingSm: `0.5rem`,
    wrapperPadding: `1rem`,
  },
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faColumns } from '@fortawesome/free-solid-svg-icons';

import theme from '../theme';
import { UserContext } from '../providers/UserProvider';
import ResponsiveSection from '../components/ResponsiveSection';

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: bold;
  padding: .1rem .5rem;
  text-decoration: none;

  &:hover {
    opacity: .75;
  }
`;

const StyledFooter = styled(ResponsiveSection)`
  .inner-div {
    padding: ${theme.layout.wrapperPaddingSm};
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin: 0 auto;
    max-width: ${theme.layout.maxWidth};
  }

  .copyright {
    align-self: baseline;
  }
  
  .footerNav {
    margin: 1rem 0;
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    .inner-div {
      align-items: center;
      border-top: none;
      flex-direction: row;
      padding: ${theme.layout.wrapperPadding};
    }
  
    .footerNav {
      margin: 0;
    }
  }
`;

const SignInLink = ({ hideSignInLink }) => {
  const user = useContext(UserContext);

  if (hideSignInLink || user === undefined) return null;
  return (
    !user
      ? (
        <StyledLink to="/dashboard/sign-in">
          <FontAwesomeIcon icon={faSignInAlt} />
          {' '}
          Sign In
        </StyledLink>
      ) : (
        <StyledLink to="/dashboard/">
          <FontAwesomeIcon icon={faColumns} />
          {' '}
          My Dashboard
        </StyledLink>
      )
  );
};

const Footer = ({ className, hideSignInLink }) => (
  <StyledFooter className={`footer ${className}`}>
    <div
      className="copyright"
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '.9em',
        fontWeight: 600,
      }}
    >
      © {new Date().getFullYear()} Digital Rabbit, Inc.
      <img src="/US-flag.svg" style={{ margin: '0 0 0 0.5rem', height: '.75rem' }} alt="US Flag" />
    </div>

    <div className="footerNav">
      <SignInLink hideSignInLink={hideSignInLink} />
    </div>
  </StyledFooter>
);

export default Footer;
